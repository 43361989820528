import { useState, useCallback } from "react";

interface MultiClickOptions {
	targetCount: number; // Number of clicks required to trigger the callback
	resetAfterTrigger?: boolean; // Whether to reset count after triggering
}

export function useMultiClick(
	callback: () => void,
	options: MultiClickOptions = { targetCount: 7, resetAfterTrigger: true }
) {
	const { targetCount, resetAfterTrigger } = options;
	const [clickCount, setClickCount] = useState(0);

	const handleClick = useCallback(() => {
	setClickCount((prevCount) => {
			const newCount = prevCount + 1;
			if (newCount === targetCount) {
				callback();
				return resetAfterTrigger ? 0 : newCount;
			}
			return newCount;
		});
	}, [callback, targetCount, resetAfterTrigger]);

	return handleClick;
}
