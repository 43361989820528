/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { Box, Button, Container, Grid, IconButton, Typography, useTheme } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import Paper from "@mui/material/Paper";
import Slide from "@mui/material/Slide";
import { isSpeechSynth, SpeechSynthText } from "./SpeechSynth";
import { AudioUrl, SpeechSynth, isAudioUrl } from "../services/sound-player";
import { DisplayImage, DisplayText, isDisplayImage, isDisplayText } from "../App";
import MarkdownQuestion from "./MarkdownQuestion";
import useSleepOnMount from "../services/useSleepOnMount";
import assert from "assert";
import logger from "../services/logger";


export interface MultiAudioViewProps {
	question: DisplayText | SpeechSynthText | AudioUrl | DisplayImage,
	onClickTrue(): void,
	onClickFalse(): void,
	onClickPlay(): void,
	onClickPlayQuestion(): void,
	onClickNext(): void,
	itemCount: number,
	activeItem: number,
	fail: boolean,
	success: boolean,
	onQuestionDone(): void,
	questionDone: boolean,
	intro: boolean,
	answers: ((SpeechSynth | AudioUrl | DisplayText | DisplayImage) & {correct: boolean})[],
	hideAnswerText: boolean,
	hideQuestionText: boolean,
}

export default function MultiAudioView(props: MultiAudioViewProps) {
	const theme = useTheme();

	const correctAnswer = props.answers.find(a => a.correct);
	assert(correctAnswer, "Could not find correct answer");
	logger.debug("correct answer", correctAnswer);

	const Item = (itemProps: {answer: ((SpeechSynth | AudioUrl | DisplayText | DisplayImage) & {correct: boolean})}) => {
		const sleepyClickTrue = useSleepOnMount(props.onClickTrue);
		const sleepyClickFalse = useSleepOnMount(props.onClickFalse);

		const answerText = (isAudioUrl(itemProps.answer) && itemProps.answer.transcript) || (isSpeechSynth(itemProps.answer) && itemProps.answer.synthText);

		return <>
		<Paper elevation={8} sx={{padding: 4, width: 256,}}>
		<Grid container
			direction="column"
			justifyContent="space-around"
			alignItems="center"
			sx={{height: "100%", minHeight: "50vh"}}
			>
		<Grid item marginBottom={4} sx={{textAlign: "center"}}>
			{isDisplayImage(itemProps.answer) && <>
				<Box 
					marginTop={4}
					marginBottom={0}
					sx={{
						textAlign: "center",
						}}
					onClick={(isSpeechSynth(itemProps.answer) || isAudioUrl(itemProps.answer)) ? props.onClickPlay : undefined}
					>
					<img 
						src={itemProps.answer.imageUrl}
						alt={itemProps.answer.alt}
						style={{
							maxWidth: "100%",
							maxHeight: "30vh",
							// boxShadow: "0px 4px 5px -2px rgba(0,0,0,0.2), 0px 7px 10px 1px rgba(0,0,0,0.14), 0px 2px 16px 1px rgba(0,0,0,0.12)",
						}}
					/>
				</Box>
			</>}
			{(isAudioUrl(itemProps.answer) || isSpeechSynth(itemProps.answer)) && !isDisplayImage(itemProps.answer) && <>
				<IconButton aria-label="delete" size="large" onClick={props.onClickPlay}>
					<VolumeUpIcon sx={{ fontSize: "60px", color: "black" }} />
				</IconButton>
				{answerText && !props.hideAnswerText && <>
					<Typography variant="body2" sx={{marginTop: 2}}>{answerText}</Typography>
				</>}
			</>}
			{isDisplayText(itemProps.answer) && <>
				<MarkdownQuestion text={itemProps.answer.displayText} sx={itemProps.answer.sx} markdownStyle={itemProps.answer.markdownStyle}/>
			</>}
		</Grid>
		<Grid item><Box 
		// marginTop="25vh"
		>
			{/* Use onTouchEnd + onMouseUp instead of onClick because otherwise it will not trigger on sluggish touches. */}
			<IconButton color="primary" onTouchEnd={sleepyClickTrue} onMouseUp={sleepyClickTrue}><CheckCircleOutlineIcon sx={{fontSize: "3.75rem", marginRight: 1, color: theme.palette.success.dark}}/></IconButton>
			<IconButton color="primary" onTouchEnd={sleepyClickFalse} onMouseUp={sleepyClickFalse}><HighlightOffIcon sx={{fontSize: "3.75rem", marginLeft: 2, color: theme.palette.error.dark}}/></IconButton>
			{/* <Button
				onClick={props.onClickTrue}
				css={css`
					margin-right: 24px;
				`}
			>
			<Typography variant="h2">✔</Typography></Button>
			<Button css={css`
				margin-left: 24px;
			`}
			onClick={props.onClickFalse}>
			<Typography variant="h2">✖</Typography></Button> */}
		</Box>
		</Grid>
		</Grid>
	</Paper>
	</>
	}

	const ItemSuccess = () => {
		const theme = useTheme();
		const sleepyClickNextHandler = useSleepOnMount(props.onClickNext);
	
		return <>
		<Paper elevation={8} sx={{
			padding: 4,
			// backgroundColor: "#EF5350",
			backgroundColor: "#94D196",
			boxShadow: "0px 5px 5px -3px rgba(27,94,32,0.2), 0px 8px 10px 1px rgba(27,94,32,0.14), 0px 3px 14px 2px rgba(27,94,32,0.12)",
			// borderStyle: "solid",
			borderColor: theme.palette.success.main,
			width: 256,
			}}
			>
		<Grid container
			direction="column"
			justifyContent="space-around"
			alignItems="center"
			sx={{height: "100%", minHeight: "50vh"}}
			>
		<Grid item marginBottom={4}>
			<IconButton aria-label="delete" size="large" onClick={props.onClickPlay}>
				<VolumeUpIcon sx={{ fontSize: "80px", color: "black" }} />
			</IconButton>
		</Grid>
		<Grid item><Box 
		// marginTop="25vh"
		>
			{/* <Button
				onClick={props.onClickTrue}
				css={css`
					margin-right: 24px;
				`}
			>
			<Typography variant="h2">✔</Typography></Button>
			<Button css={css`
				margin-left: 24px;
			`}
			onClick={props.onClickFalse}>
			<Typography variant="h2">✖</Typography></Button> */}
			<Button variant="contained" size="large" sx={{fontSize: 20}} color="success" onClick={sleepyClickNextHandler}>⟫&nbsp;&nbsp;⟫&nbsp;&nbsp;⟫</Button>
		</Box>
		</Grid>
		</Grid>
	</Paper>
	</>
	}

	const ItemFail = () => {
		const sleepyClickNext = useSleepOnMount(props.onClickNext);
		logger.debug("correctAnswer in ItemFail", correctAnswer, isAudioUrl(correctAnswer));

		return <>
		<Paper elevation={8} sx={{
			padding: 4,
			// backgroundColor: "#EF5350",
			backgroundColor: "hsl(1 83% 93% / 1)",
			boxShadow: "0px 5px 5px -3px rgba(198,40,40,0.2), 0px 8px 10px 1px rgba(198,40,40,0.14), 0px 3px 14px 2px rgba(198,40,40,0.12)",
			// borderStyle: "solid",
			borderColor: theme.palette.error.main,
			width: 256,
			}}
			>
		<Grid container
			direction="column"
			justifyContent="space-around"
			alignItems="center"
			sx={{height: "100%", minHeight: "50vh"}}
			>
			<Grid item marginBottom={4} sx={{textAlign: "center"}}>
				{isDisplayImage(correctAnswer) && <>
					<Box 
						marginTop={4}
						marginBottom={0}
						sx={{
							textAlign: "center",
							}}
						onClick={(isSpeechSynth(correctAnswer) || isAudioUrl(correctAnswer)) ? props.onClickPlay : undefined}
						>
						<img 
							src={correctAnswer.imageUrl}
							alt={correctAnswer.alt}
							style={{
								maxWidth: "100%",
								maxHeight: "30vh",
								// boxShadow: "0px 4px 5px -2px rgba(0,0,0,0.2), 0px 7px 10px 1px rgba(0,0,0,0.14), 0px 2px 16px 1px rgba(0,0,0,0.12)",
							}}
						/>
					</Box>
				</>}
				{(isAudioUrl(correctAnswer) || isSpeechSynth(correctAnswer)) && <>
					<IconButton aria-label="delete" size="large" onClick={props.onClickPlay}>
						<VolumeUpIcon sx={{ fontSize: "80px", color: "black" }} />
					</IconButton>
				</>}
				{isDisplayText(correctAnswer) && <>
					<MarkdownQuestion text={correctAnswer.displayText} sx={correctAnswer.sx} markdownStyle={correctAnswer.markdownStyle}/>
				</>}
			</Grid>
		<Grid item><Box 
		// marginTop="25vh"
		>
			{/* <Button
				onClick={props.onClickTrue}
				css={css`
					margin-right: 24px;
				`}
			>
			<Typography variant="h2">✔</Typography></Button>
			<Button css={css`
				margin-left: 24px;
			`}
			onClick={props.onClickFalse}>
			<Typography variant="h2">✖</Typography></Button> */}
			<Button variant="contained" size="large" sx={{fontSize: 20}} color="error" onClick={sleepyClickNext}>⟫&nbsp;&nbsp;⟫&nbsp;&nbsp;⟫</Button>
		</Box>
		</Grid>
		</Grid>
	</Paper>
	</>
	}

	const ItemNew = () => {
		const sleepyClickNext = useSleepOnMount(props.onClickNext);

		return <>
		<Paper elevation={8} sx={{
			padding: 4,
			// backgroundColor: "#EF5350",
			backgroundColor: "rgb(229, 246, 253)",
			// boxShadow: "0px 5px 5px -3px rgba(198,40,40,0.2), 0px 8px 10px 1px rgba(198,40,40,0.14), 0px 3px 14px 2px rgba(198,40,40,0.12)",
			// borderStyle: "solid",
			// borderColor: theme.palette.error.main,
			width: 256,
			}}
			>
		<Grid container
			direction="column"
			justifyContent="space-around"
			alignItems="center"
			sx={{height: "100%", minHeight: "50vh"}}
			>
		<Grid item marginBottom={4}>
			<IconButton aria-label="delete" size="large" onClick={props.onClickPlay}>
				<VolumeUpIcon sx={{ fontSize: "80px", color: "black" }} />
			</IconButton>
		</Grid>
		<Grid item>
			<Box>
				<Button variant="contained" size="large" sx={{fontSize: 20}} color="info" onClick={sleepyClickNext}>⟫&nbsp;&nbsp;⟫&nbsp;&nbsp;⟫</Button>
			</Box>
		</Grid>
		</Grid>
	</Paper>
	</>
	}




	
	return <>
	<Container>
		<Grid container
			direction="column"
			justifyContent="space-around"
			alignItems="center"
			
  			>
			<Grid item>
				{isDisplayImage(props.question) ?
					<Box 
						marginTop={4}
						marginBottom={0}
						sx={{
							textAlign: "center",
							}}
						onClick={(isSpeechSynth(props.question) || isAudioUrl(props.question)) ? props.onClickPlayQuestion : undefined}
						>
						<img 
							src={props.question.imageUrl}
							alt={props.question.alt}
							style={{
								maxWidth: "100%",
								maxHeight: "30vh",
								boxShadow: "0px 4px 5px -2px rgba(0,0,0,0.2), 0px 7px 10px 1px rgba(0,0,0,0.14), 0px 2px 16px 1px rgba(0,0,0,0.12)",
							}}
						/>
					</Box>
				:
					<Box 
						marginTop={8}
						marginBottom={6}
						sx={{textAlign: "center"}}
						>
							{isDisplayText(props.question) && <MarkdownQuestion text={props.question.displayText} sx={props.question.sx} markdownStyle={props.question.markdownStyle}/>}
							{/* {isDisplayText(props.question) && <Typography variant="h1" color="default"><strong>{props.question.displayText}</strong></Typography>} */}
							{(isSpeechSynth(props.question) || isAudioUrl(props.question)) && <>
								{/* <SpeechSynth synthText={props.question.synthText} onEnd={props.onQuestionDone}/>} */}
								<IconButton aria-label="delete" size="large" onClick={props.onClickPlayQuestion}>
									<VolumeUpIcon sx={{ fontSize: "60px", color: "black" }} />
								</IconButton>
								{isAudioUrl(props.question) && props.question.transcript && !props.hideQuestionText && <>
									<Typography variant="body2" sx={{marginTop: 2}}>{props.question.transcript}</Typography>
								</>}
							</>}
					</Box>
				}
			</Grid>
			<Grid item marginTop={4}>
				<Box>
				{[...Array(props.itemCount)].map((value, index) => <>
					<Box>
					<Slide direction={index === props.activeItem ? "up" : "down"} in={props.questionDone && index === props.activeItem} mountOnEnter unmountOnExit>
						<div>
							{props.intro && <ItemNew/>}
							{!props.intro && <>
								{props.success && <ItemSuccess/>}
								{props.fail && <ItemFail/>}
								{!props.success && !props.fail && <Item answer={props.answers[index]}/>}
							</>}
						</div>
					</Slide>
					</Box>
				</>)}
				</Box>
			</Grid>
		</Grid>
	</Container>
	</>;
}