import { AnySpecificKitem } from "../AnySpecificKitem";
import { KitemType } from "../contexts/LearningContext";


export const EnglishItemset = [
	{id: "dog", imageUrl: require("../assets/erste-worte/hund.jpg"), audioUrl: require("../assets/englisch/dog.m4a")},
	{id: "cat", imageUrl: require("../assets/erste-worte/katze.jpg"), audioUrl: require("../assets/englisch/cat.m4a")},
	{id: "sheep", imageUrl: require("../assets/erste-worte/schaf.jpg"), audioUrl: require("../assets/englisch/sheep.m4a")},
	{id: "car", imageUrl: require("../assets/erste-worte/auto.jpg"), audioUrl: require("../assets/englisch/car.m4a")},
	{id: "bicycle", imageUrl: require("../assets/erste-worte/fahrrad.jpg"), audioUrl: require("../assets/englisch/bicycle.m4a")},
	{id: "cow", imageUrl: require("../assets/erste-worte/kuh.jpg"), audioUrl: require("../assets/englisch/cow.m4a")},
	{id: "pig", imageUrl: require("../assets/erste-worte/schwein.jpg"), audioUrl: require("../assets/englisch/pig.m4a")},
	{id: "chair", imageUrl: require("../assets/erste-worte/stuhl.jpg"), audioUrl: require("../assets/englisch/chair.m4a")},
	{id: "dragon", imageUrl: require("../assets/lesen/drache.jpg"), audioUrl: require("../assets/englisch/dragon.m4a")},
	{id: "excavator", imageUrl: require("../assets/lesen/bagger.jpg"), audioUrl: require("../assets/englisch/excavator.m4a")},
	{id: "hedgehog", imageUrl: require("../assets/lesen/igel.jpg"), audioUrl: require("../assets/englisch/hedgehog.m4a")},
	{id: "house", imageUrl: require("../assets/lesen/haus.jpg"), audioUrl: require("../assets/englisch/house.m4a")},
	{id: "monkey", imageUrl: require("../assets/lesen/affe.jpg"), audioUrl: require("../assets/englisch/monkey.m4a")},
	{id: "mouse", imageUrl: require("../assets/lesen/maus.jpg"), audioUrl: require("../assets/englisch/mouse.m4a")},
	{id: "pirate", imageUrl: require("../assets/lesen/pirat.jpg"), audioUrl: require("../assets/englisch/pirate.m4a")},
	{id: "strawberry", imageUrl: require("../assets/lesen/erdbeere.jpg"), audioUrl: require("../assets/englisch/strawberry.m4a")},
	{id: "sun", imageUrl: require("../assets/lesen/sonne.jpg"), audioUrl: require("../assets/englisch/sun.m4a")},

	{id: "sheep", imageUrl: require("../assets/erste-worte/schaf.jpg"), audioUrl: require("../assets/englisch/sheep.m4a")},
	{id: "duck",
		imageUrls: [
			require("../assets/erste-worte/ente.jpg"),
			require("../assets/erste-worte/ente-2.jpg"),
		],
		audioUrls: [
			require("../assets/englisch/duck.m4a"),
		]
	},
	{id: "lion",
		imageUrls: [
			require("../assets/erste-worte/loewe.jpg"),
			require("../assets/erste-worte/loewe-2.jpg"),
			require("../assets/erste-worte/loewe-StockCake-Majestic-Lion-Sunset_1713084151.jpg"),
		],
		audioUrls: [
			require("../assets/englisch/lion.m4a"),
		],
	},
	{id: "fox",
		imageUrls: [
			require("../assets/erste-worte/fuchs-StockCake-Winter-Fox-Stroll_1713084068.jpg"),
			require("../assets/erste-worte/fuchs-StockCake-Majestic-Fox-Stance_1713084047.jpg"),
			require("../assets/erste-worte/fuchs-StockCake-Curious-Fox-Stare_1713084010.jpg"),
		],
		audioUrls: [
			require("../assets/englisch/fox.m4a"),
		],
	},
	{id: "pigeon",
		imageUrls: [
			require("../assets/erste-worte/taube-StockCake-Pigeon-Urban-Perch_1713083423.jpg"),
			require("../assets/erste-worte/taube-StockCake-Urban-Pigeon-Stance_1713083382.jpg"),
		],
		audioUrls: [
			require("../assets/englisch/pigeon.m4a"),
		],
	},
	{id: "penguin",
		imageUrls: [
			require("../assets/erste-worte/pinguin-StockCake-Majestic-Penguin-Strut_1713083124.jpg"),
			require("../assets/erste-worte/pinguin-StockCake-Penguin-in-Ice_1713083268.jpg"),
			require("../assets/erste-worte/pinguin-StockCake-Penguin-on-Ice_1713083140.jpg"),
		],
		audioUrls: [
			require("../assets/englisch/penguin.m4a"),
		],
	},
	{id: "frog",
		imageUrls: [
			require("../assets/erste-worte/frosch-StockCake-Frog-Pond-Reflection_1713083715.jpg"),
			require("../assets/erste-worte/frosch-StockCake-Frog-Pond-Repose_1713083576.jpg"),
		],
		audioUrls: [
			require("../assets/englisch/frog.m4a"),
		],
	},
	{id: "apple",
		imageUrls: [
			require("../assets/erste-worte/apfel.jpg"),
			require("../assets/erste-worte/apfel-2.jpg"),
		],
		audioUrls: [
			require("../assets/englisch/apple.m4a"),
		]
	},
	{id: "rocket",
		imageUrls: [
			require("../assets/erste-worte/rakete.jpg"),
			require("../assets/erste-worte/rakete-2.jpg"),
			require("../assets/erste-worte/rakete-StockCake-Rocket-Launch-Skyward_1713084526.jpg"),
			require("../assets/erste-worte/rakete-StockCake-Liftoff-into-Space_1713084393.jpg"),
			require("../assets/erste-worte/rakete-StockCake-Rocket-Launch-Thrust_1713084307.jpg"),
		],
		audioUrls: [
			require("../assets/englisch/rocket.m4a"),
		],
	},
	{id: "tractor",
		imageUrls: [
			require("../assets/erste-worte/traktor-StockCake-Sunset-Farming-Operations_1713084902.jpg"),
			require("../assets/erste-worte/traktor-StockCake-Stormy-Field-Plowing_1713084813.jpg"),
			require("../assets/erste-worte/traktor-StockCake-Tractor-Tilling-Soil_1713084715.jpg"),
			require("../assets/erste-worte/traktor-StockCake-Rustic-Farm-Tractor_1713084594.jpg"),
		],
		audioUrls: [
			require("../assets/englisch/tractor.m4a"),
		],
	},
	{id: "moon",
		imageUrls: [
			require("../assets/erste-worte/mond-StockCake-Luminous-Full-Moon_1713646131.jpg"),
			require("../assets/erste-worte/mond-StockCake-Moonlit-Ocean-View_1713646122.jpg"),
		],
		audioUrls: [
			require("../assets/englisch/moon.m4a"),
		],
	},
	{id: "ball",
		imageUrls: [
			require("../assets/erste-worte/ball-StockCake-Beach-Ball-Fun_1713647636.jpg"),
			require("../assets/erste-worte/ball-StockCake-Baseball-Up-Close_1713648539.jpg"),
			require("../assets/erste-worte/ball-StockCake-Sunset-Basketball-Game_1713648481.jpg"),
			require("../assets/erste-worte/ball-StockCake-Red-ball-solo_1713648355.jpg"),
			require("../assets/erste-worte/ball-StockCake-Nighttime-Soccer-Setting_1713647735.jpg"),
			require("../assets/erste-worte/ball-StockCake-Stadium-Soccer-Night_1713647648.jpg"),
		],
		audioUrls: [
			require("../assets/englisch/moon.m4a"),
		],
	},
];

// letztes Tier: Frosch

export const EnglishKitems: (AnySpecificKitem)[] = EnglishItemset.map(item => ({
	id: "english_"+item.id,
	type: KitemType.ImageChooser,
	topic: "englisch",
	setId: "englisch",
	targetId: item.id,
}));

export const EnglishKitems_old: (AnySpecificKitem)[] = [
	"car",
	"bicycle",
	"dog",
	"cat",
	"cow",
	"pig",
	"sheep",
	"chair",
	"dragon",
	"excavator",
	"hedgehog",
	"house",
	"monkey",
	"mouse",
	"pirate",
	"strawberry",
	"sun",
].map(key => (
	{
		id: "english_"+key,
		type: KitemType.ImageChooser,
		topic: "englisch",
		setId: "englisch",
		targetId: key,
	}
));