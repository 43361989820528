import React, { useEffect, useRef } from 'react';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import { IconButton} from '@mui/material';
import { AudioUrl, playSound, SoundPlayer, SpeechSynth } from '../services/sound-player';


export interface AudioButtonProps {
	audio: SpeechSynth | AudioUrl,
	size?: "small" | "xxl",
	autoPlay?: boolean,
	autoPlayDelay?: number,
	keepQueue?: boolean,
	content?: React.ReactNode,
}


export default function AudioButton(props: AudioButtonProps) {
	const player = useRef<SoundPlayer|null>(null)

	useEffect(() => {
		let timeoutId: number;
	
		if (props.autoPlay) {
			if (player.current) {
				player.current.stop();
			}
			timeoutId = window.setTimeout(() => {
				player.current = playSound(props.audio, props.keepQueue);
				}, props.autoPlayDelay || 0);
		}
	
		return () => {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
	
			if (player.current) {
				player.current.stop();
			}
		};
	}, [props.autoPlay, props.audio]);

	const handlePlay = () => {
		if (player.current)
			player.current.stop();
		player.current = playSound(props.audio);
	}

	const fontSize = props.size === "xxl" ? "6rem" : "1.25rem";

	if (props.content) {
		return <>
			<div onClick={handlePlay}>
				{props.content}
			</div>
		</>;
	} else {
		return <>
			<IconButton onClick={handlePlay}>
				<PlayCircleFilledWhiteOutlinedIcon sx={{fontSize: fontSize}}/>
			</IconButton>
		</>;
	}

}