import React from 'react';
import { TopicStatus } from '../services/useTopics';
import { KitemUnsaved, LearningPackage } from './LearningProvider';

export enum KitemType {
	SMC = 1,
	Rockets = 2,
	ImageChooser = 3,
	WrittenAnswer = 4,
}

export interface KitemProgress {
	id: string,
	kitemId: string,
	due: Date,
	state: KitemProgressState,
	easiness: number,
	reps: KitemRep[],
}

export enum KitemProgressState {
	new = 1,
	success = 2,
	fail = 3,
}

export interface KitemRep {
	id: string,
	date: Date,
	success: boolean,
	kitemId: string,
}

export interface Kitem {
	id: string,
	type: KitemType,
	topic: string,
}

export interface Topic {
	id: string,
	title: string,
}

export interface Learning {
	getCurriculum(topic?: string): KitemProgress[],
	getLearningPackage(): LearningPackage,
	extendLearningPackage(packageId: string, topicId: string | null): LearningPackage,
	learningPackageIsExtendable: (packageId: string, topicId: string | null) => boolean,
	deleteTodaysLearningPackages(): void,
	recordItemResultInPackage(topic: string, pitemId: string, success: boolean): void,
	recordItemResultInAnyPackage(date: string, pitemId: string, success: boolean): void,
	getKitems(topic?: string): Kitem[],
	getKitem(kitemId: string): Kitem | undefined,
	addKitem(kitemUnsaved: KitemUnsaved): void,
	updateKitem(kitem: Kitem): void,
	deleteKitem(kitemId: string): void,
	addRep(kitemId: string, success: boolean): void,
	addRepObject(rep: KitemRep): void,
	filterKitemIdsByTopic(kitemIds: string[], topic: string): string[],
	getReps(): Promise<KitemRep[]>,
	deleteAllLocalReps(): void,
	getProgress(kitemId: string): KitemProgress | undefined,
	getAllProgress(): KitemProgress[],
	learningPackageMaxTotalItems: number,
	setLearningPackageMaxTotalItems(size: number): void,
	learningPackageMaxHardItems: number,
	setLearningPackageMaxHardItems(size: number): void,
	learningPackageMaxNewItems: number,
	setLearningPackageMaxNewItems(size: number): void,
	getTopicWeight(topicId: string): number,
	setTopicWeight(topicId: string, weight: number): void,
	getTopicStatus(topicId: string): TopicStatus,
	setTopicStatus(topicId: string, status: TopicStatus): void,
}

const LearningContext = React.createContext<Learning>({
	getCurriculum: (topic?: string) => [],
	getLearningPackage: () => {throw new Error("No LearningProvider available")},
	extendLearningPackage: (packageId: string, topicId: string | null) => {throw new Error("No LearningProvider available")},
	learningPackageIsExtendable: (packageId: string, topicId: string | null) => false,
	deleteTodaysLearningPackages: () => {},
	getProgress: (kitemId: string) => {throw new Error("No LearningProvider available")},
	getAllProgress: () => [],
	recordItemResultInPackage: (topic: string, pitemId: string, success: boolean) => {},
	recordItemResultInAnyPackage: (date: string, pitemId: string, success: boolean) => {},
	getKitems: (topic?: string) => [],
	getKitem: (kitemId: string) => {throw new Error("No LearningProvider available")},
	addKitem: (kitemUnsaved: KitemUnsaved ) => {},
	updateKitem: (kitem: Kitem ) => {},
	deleteKitem: (kitemId: string) => {},
	filterKitemIdsByTopic: (kitemIds: string[], topic: string) => [],
	addRep: (kitemId: string, success: boolean) => {},
	addRepObject: (rep: KitemRep) => {},
	getReps: async () => [],
	deleteAllLocalReps: () => {},
	learningPackageMaxTotalItems: 10,
	setLearningPackageMaxTotalItems: (size: number) => {},
	learningPackageMaxHardItems: 10,
	setLearningPackageMaxHardItems: (size: number) => {},
	learningPackageMaxNewItems: 2,
	setLearningPackageMaxNewItems: (size: number) => {},
	getTopicWeight: (topicId: string) => 1,
	setTopicWeight: (topicId: string, weight: number) => {throw new Error("No LearningProvider available")},
	getTopicStatus: (topicId: string) => "inactive",
	setTopicStatus: (topicId: string, status: TopicStatus) => {throw new Error("No LearningProvider available")},
});

export default LearningContext;
