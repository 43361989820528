import { useEffect, useRef } from "react";
import logger from "./logger";

/**
 * Custom hook which takes any function as an argument and returns a function with the original
 * function's signature that does one of two things:
 * - nothing if the component has been mounted for less than half a second or has already been called less than half a second ago
 * - calls the original function with its original parameters if the component has been mounted for at least half a second
 */
export default function useSleepOnMount<T extends (...args: any[]) => any>(callback: T) {
	const callbackRef = useRef(callback);
	const mountTimeRef = useRef<number>(0);
  
	// Save the callback function to a ref to avoid changes in the callback triggering the effect.
	useEffect(() => {
	  callbackRef.current = callback;
	}, [callback]);
  
	useEffect(() => {
	  mountTimeRef.current = Date.now();
	}, []);
  
	// The returned function has the same signature as the original callback function.
	const sleepyCallback = (...args: Parameters<T>) => {
	  const currentTime = Date.now();
	  if (currentTime - mountTimeRef.current >= 500) {
		// If enough time has passed, call the original callback.
		callbackRef.current(...args);
		mountTimeRef.current = currentTime;
	  } else {
		// If the component hasn't been mounted long enough yet, do nothing.
		logger.debug("useSleepOnMount: Not enough time since mount or last trigger");
	  }
	};
  
	return sleepyCallback;
}
