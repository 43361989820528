
export interface ProfileInformation {
	id: string,
	name: string,
}

export const profileList = [
	{id: "e25afe3d-c89c-4b19-8eab-37ac45f6626d", name: "Janosch"},
	{id: "46434d5c-345f-443d-bc1a-187d53ee4f04", name: "Paul"},
	{id: "8531dd2e-6ed1-45df-960f-1d5b80980025", name: "Test 1"},
	{id: "0bc84116-5ca7-439c-b004-94aa4e194e69", name: "Test 2"},
	{id: "d1f642bb-41ca-4fe3-93b4-3c1ec1c0bd74", name: "Pixel 7 Pro"},
	{id: "8eafd3bf-c52a-43bd-8851-c0d8738321cf", name: "Fire Kids Tablet"},
	{id: "4f8fffae-9c5d-48c0-ba7a-c432beed6f4e", name: "Fire Kids Tablet 2"},
]