import { IconButton } from "@mui/material";
import { useEffect } from "react";
import { AudioUrl, speechSynth } from "../services/sound-player";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { DisplayImage, DisplayText } from "../App";


export interface SpeechSynthText {
	synthText: string,
	onEnd?(): void,
}

export function isSpeechSynth(text: SpeechSynthText | AudioUrl | DisplayText | DisplayImage): text is SpeechSynthText {
	return (text as SpeechSynthText).synthText !== undefined;
}

export default function SpeechSynth(props: SpeechSynthText) {
	useEffect(() => {
		speechSynth(props.synthText, false, props.onEnd);
	}, [props.synthText]);

	const handleClick = () => speechSynth(props.synthText, false, props.onEnd);

	return <>
		<IconButton aria-label="delete" size="large" onClick={handleClick}>
			<VolumeUpIcon sx={{ fontSize: "60px", color: "black" }} />
		</IconButton>
	</>;
}