import { KitemType } from "../contexts/LearningContext";
import { ImageChooserKitem } from "../controller/ImageChooserController";


export const ErsteWorteKitems: (ImageChooserKitem)[] = [
	{
		id: "ersteworte_mama",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "personen",
		targetId: "mama",
		// variant: "first-words",
	},
	{
		id: "ersteworte_papa",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "personen",
		targetId: "papa",
		// variant: "first-words",
	},
	{
		id: "ersteworte_janosch",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "personen",
		targetId: "janosch",
		// variant: "first-words",
	},
	{
		id: "ersteworte_hund",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "hund",
		// variant: "first-words",
	},
	{
		id: "ersteworte_apfel",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "sonstiges",
		targetId: "apfel",
	},
	{
		id: "ersteworte_katze",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "katze",
		// variant: "first-words",
	},
	{
		id: "ersteworte_schaf",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "schaf",
		// variant: "first-words",
	},
	{
		id: "ersteworte_pferd",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "pferd",
		// variant: "first-words",
	},
	{
		id: "ersteworte_ball",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "sonstiges",
		targetId: "ball",
	},
	{
		id: "ersteworte_maus",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "maus",
		// variant: "first-words",
	},
	{
		id: "ersteworte_baum",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "sonstiges",
		targetId: "baum",
	},
	{
		id: "ersteworte_ente",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "ente",
	},
	{
		id: "ersteworte_bagger",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "sonstiges",
		targetId: "bagger",
	},
	{
		id: "ersteworte_schwein",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "schwein",
	},
	{
		id: "ersteworte_auto",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "sonstiges",
		targetId: "auto",
	},
	{
		id: "ersteworte_affe",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "affe",
	},
	{
		id: "ersteworte_banane",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "sonstiges",
		targetId: "banane",
	},
	{
		id: "ersteworte_kuh",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "kuh",
	},
	{
		id: "ersteworte_kran",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "sonstiges",
		targetId: "kran",
	},
	{
		id: "ersteworte_igel",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "igel",
	},
	{
		id: "ersteworte_blume",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "sonstiges",
		targetId: "blume",
	},
	{
		id: "ersteworte_loewe",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "loewe",
	},
	{
		id: "ersteworte_bus",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "sonstiges",
		targetId: "bus",
	},
	{
		id: "ersteworte_fisch",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "fisch",
	},
	{
		id: "ersteworte_stuhl",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "sonstiges",
		targetId: "stuhl",
	},
	{
		id: "ersteworte_fuchs",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "fuchs",
	},
	{
		id: "ersteworte_zug",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "sonstiges",
		targetId: "zug",
	},
	{
		id: "ersteworte_taube",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "taube",
	},
	{
		id: "ersteworte_erdbeere",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "sonstiges",
		targetId: "erdbeere",
	},
	{
		id: "ersteworte_pinguin",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "pinguin",
	},
	{
		id: "ersteworte_gurke",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "sonstiges",
		targetId: "gurke",
	},
	{
		id: "ersteworte_frosch",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "frosch",
	},
	{
		id: "ersteworte_eule",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "eule",
	},
	{
		id: "ersteworte_biene",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "biene",
	},
	{
		id: "ersteworte_huhn",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "huhn",
	},
	{
		id: "ersteworte_krokodil",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "krokodil",
	},
	{
		id: "ersteworte_moewe",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "moewe",
	},
	{
		id: "ersteworte_hase",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "hase",
	},
	{
		id: "ersteworte_kueken",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "kueken",
	},
	{
		id: "ersteworte_elefant",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "elefant",
	},
	{
		id: "ersteworte_krebs",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "krebs",
	},
	{
		id: "ersteworte_schmetterling",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "schmetterling",
	},
	{
		id: "ersteworte_papagei",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "papagei",
	},
	{
		id: "ersteworte_schwan",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "schwan",
	},
	{
		id: "ersteworte_schildkroete",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "schildkroete",
	},

	{
		id: "ersteworte_fahrrad",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "sonstiges",
		targetId: "fahrrad",
	},

	{
		id: "ersteworte_rakete",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "sonstiges",
		targetId: "rakete",
	},
	{
		id: "ersteworte_traktor",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "sonstiges",
		targetId: "traktor",
	},
	{
		id: "ersteworte_mond",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "sonstiges",
		targetId: "mond",
	},
	{
		id: "ersteworte_pizza",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "sonstiges",
		targetId: "pizza",
	},
	{
		id: "ersteworte_hubschrauber",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "sonstiges",
		targetId: "hubschrauber",
	},
	{
		id: "ersteworte_flugzeug",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "sonstiges",
		targetId: "flugzeug",
	},
	{
		id: "ersteworte_brokkoli",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "sonstiges",
		targetId: "brokkoli",
	},
	{
		id: "ersteworte_tomate",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "sonstiges",
		targetId: "tomate",
	},
	{
		id: "ersteworte_windrad",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "sonstiges",
		targetId: "windrad",
	},
];