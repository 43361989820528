import { logger } from '@sentry/utils';
import React, { useState, useContext, useEffect } from 'react';
import FlashCardView from '../components/FlashCardView';
import { Kitem } from '../contexts/LearningContext';
import { SMCKitem } from './SMCController';

export type FlashCardState = "question" | "evaluation";

interface FlashCardControllerProps {
	kitem: SMCKitem,
	onSuccess(): void,
	onFail(): void,
}

export default function FlashCardController(props: FlashCardControllerProps) {
	const [cardState, setCardState] = useState<FlashCardState>("question")

	const handleClickShow = () => {
		setCardState("evaluation");
	}

	const correctAnswer = props.kitem.answers.find(a => a.correct);
	if (!correctAnswer)
		throw new Error("Correct answer not found");

	return <>
		<FlashCardView question={props.kitem.question} answer={correctAnswer} onClickCorrect={props.onSuccess} onClickWrong={props.onFail} onClickShow={handleClickShow} state={cardState}/>
	</>;
}
