import assert from 'assert';
import React, { useState, useContext, useEffect } from 'react';
import LearningSessionDoneDialog, { PlainTextQuestion } from '../components/LearningSessionDoneDialog';
import DialogsContext from '../contexts/DialogsContext';
import LearningContext from '../contexts/LearningContext';
import { LearningPackage } from '../contexts/LearningProvider';
import logger from '../services/logger';
import { getText } from '../services/textTools';
import { isSMCKitem } from './SMCController';
import * as Sentry from "@sentry/react";
import { isWrittenAnswerKitem } from './WrittenAnswerController';
import { playSound } from '../services/sound-player';

interface LearningSessionDoneProps {
	onBack(): void,
	learningPackage: LearningPackage,
	topicId: string | null,
}

export default function LearningSessionDoneController(props: LearningSessionDoneProps) {
	const learning = useContext(LearningContext);
	const dialogs = useContext(DialogsContext);
	const [storyLoading, setStoryLoading] = useState(false);

	logger.debug("LearningSessionDoneController", props.learningPackage, props.topicId);

	useEffect(() => {
		playSound({audioUrl: require('../assets/super-alles-geschafft.m4a')});
	}, []);

	const plannedKitemIds = props.topicId ? learning.filterKitemIdsByTopic(props.learningPackage.plannedKitemIds, props.topicId) : props.learningPackage.plannedKitemIds;

	const normalQuestions: PlainTextQuestion[] = [];
	const hardQuestions: PlainTextQuestion[] = [];
	plannedKitemIds.forEach(kid => {
		const kitem = learning.getKitem(kid);
		const pitem = learning.getProgress(kid);
		assert(kitem && pitem);
		let sQuestion: undefined | string = undefined;
		let sAnswer: undefined | string = undefined;
		if (isSMCKitem(kitem)) {
			const question = kitem.question;
			const answer = kitem.answers.find(a => a.correct);
			assert(question, "Question not found");
			assert(answer, "Answer not found");
			sQuestion = getText(question);
			sAnswer = getText(answer);
		}
		if (isWrittenAnswerKitem(kitem)) {
			const question = kitem.question;
			sAnswer = kitem.answers[0];
			assert(question, "Question not found");
			assert(typeof sAnswer === "string", "Unexpected answer type "+typeof sAnswer);
			sQuestion = getText(question);
		}
		// Todo: Other Kitem types
		// ImageChooser: "Welches Bild zeigt folgendes? [transcript]"
		// RocketGame: "Tippe auf die Raketen, die folgendes enthalten: [transscript]"
		// WrittenAnswer: 
		// Or maybe just the topic?
		// ImageChooser: "100", "1000", "cat", ...
		// RocketGame: "A", "3 Punkte", ...
		if (sQuestion && sAnswer) {
			// we have data for question and answer, now check if it is a standard or hard question
			const q = {question: sQuestion, answer: sAnswer};
			if (pitem.easiness >= 3) {
				normalQuestions.push(q);
			} else {
				hardQuestions.push(q);
			}
		}
	});

	const handleStoryClick = async () => {
		logger.info("Loading learning story...");
		setStoryLoading(true);
		const topics = await dialogs.prompt("Weitere Themen (optional)");
		if (topics === null) {
			setStoryLoading(false);
			return;
		}
		const request = {
			normalQuestions: normalQuestions,
			hardQuestions: hardQuestions,
			topics: topics,
		}
		fetch(
			//@ts-ignore
			"https://vf-backend.focalityapp.com/api/learningstories/v1",
			{
				method: 'POST', // *GET, POST, PUT, DELETE, etc.
				// mode: 'cors', // no-cors, *cors, same-origin
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(request),
			}
		)
		.then((response: Response) => {
			if (!response.ok) {
				throw new Error('Network response was not OK. Status code '+response.status+' / '+response.statusText);
			}
			return response.json()
		})
		.then((jsonResponse: any) => {
			logger.debug("Learning story response", jsonResponse);
			if (jsonResponse.success === false) {
				throw new Error("Story generation was unsuccessful: "+jsonResponse.errorMessage);
			}
			dialogs.alert({message: jsonResponse.response, title: "Lerngeschichten"});
			setStoryLoading(false);
		})
		.catch((e: any) => {
			const message = ""+e;
			logger.error(message);
			Sentry.captureException(e);
			dialogs.alert({title: "Fehler beim Abruf der Lerngeschichte", message: message});
			setStoryLoading(false);
		});
		
	}

	const handleAddFew = () => {
		learning.extendLearningPackage(props.learningPackage.id, props.topicId);
	}

	const isExtendable = learning.learningPackageIsExtendable(props.learningPackage.id, props.topicId);

	return <>
		<LearningSessionDoneDialog
			onClickIndex={props.onBack}
			normalQuestions={normalQuestions}
			hardQuestions={hardQuestions}
			onClickStory={handleStoryClick}
			storyLoading={storyLoading}
			onClickLearnMore={handleAddFew}
			extendable={isExtendable}
		/>
	</>;
}
