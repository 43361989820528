import { LinearProgress, Typography } from '@mui/material';
import React, { useContext, useRef, useState } from 'react';
import LoadingView from '../components/LoadingView';
import LearningContext, { KitemProgressState } from '../contexts/LearningContext';
import logger from '../services/logger';
import FlashCardController from './FlashCardController';
import LearningSessionDoneController from './LearningSessionDoneController';
import RocketGameController, { isRocketKitem } from './RocketGameController';
import SMCController, {isSMCKitem} from './SMCController';
import ImageChooserController, { isImageChooserKitem } from './ImageChooserController';
import assert from 'assert';
import WrittenAnswerController, { isWrittenAnswerKitem } from './WrittenAnswerController';
import RedoIcon from '@mui/icons-material/Redo';
import { IconButton } from '@mui/material';

interface LearningSessionControllerProps {
	topic: string | null,
	selfEvalMode: boolean,
	onBack(): void,
}

export default function LearningSessionController(props: LearningSessionControllerProps) {
	const learning = useContext(LearningContext);
	const learningPackage = learning.getLearningPackage();
	const [skippedKitemIds, setSkippedKitemIds] = useState<string[]>([]);
	const plannedKitemIds = props.topic ? learning.filterKitemIdsByTopic(learningPackage.plannedKitemIds, props.topic) : learningPackage.plannedKitemIds;
	const remainingKitemIds = (props.topic ? learning.filterKitemIdsByTopic(learningPackage.remainingKitemIds, props.topic) : learningPackage.remainingKitemIds).filter(id => !skippedKitemIds.includes(id));
	// store a ref of remainingKitemIds to prevent issues with closures in callbacks (see below e.g. with handleNext)
	const remainingKitemIdsRef = useRef<string[]>(remainingKitemIds);
	remainingKitemIdsRef.current = remainingKitemIds;

	const [currentKitemId, setCurrentKitemId] = useState<string|undefined>(remainingKitemIds[0]);
	const [iteration, setIteration] = useState(0);

	const introDone = useRef<string[]>([]);
	const nextPending = useRef(false);

	if (remainingKitemIds.length === 0) {
		// We worked through the learning package. But maybe we skipped some items? If we did, reset the skipped items and work through the whole rest
		if (skippedKitemIds.length > 0) {
			setSkippedKitemIds([]);
			return <LoadingView what="returning to skipped items"/>;
		} else {
			// show dialog telling that all learning has been completed
			return <LearningSessionDoneController onBack={props.onBack} learningPackage={learningPackage} topicId={props.topic}/>;
		}
	}

	if (currentKitemId === undefined && remainingKitemIds.length > 0) {
		setCurrentKitemId(remainingKitemIds[0]);
		return <LoadingView what="current kitem"/>
	}

	assert(currentKitemId);
	const currentPitem = learning.getProgress(currentKitemId);
	assert(currentPitem);
	
	logger.debug("LearningSessionController: learning package", learningPackage);
	logger.debug("LearningSessionController: currentKitemId", currentKitemId);




	// const isIntro = currentPitem.state === KitemProgressState.new && !introDone.current.some(id => id === currentKitemId);
	const isIntro = false;

	const currentKitem = learning.getKitem(currentPitem.kitemId);
	// const currentKitem = learning.getKitem("lesen_PA");
	if (!currentKitem) {
		logger.error("Matching item not found. Pitem:", currentPitem);
		throw new Error("Matching item not found");
	}
	if (!isSMCKitem(currentKitem) && !isRocketKitem(currentKitem) && !isImageChooserKitem(currentKitem) && !isWrittenAnswerKitem(currentKitem)) {
		logger.error("Unknown kitem type ", currentKitem);
		throw new Error("Unknown kitem type "+currentKitem.type);
	}
	const handleSuccess = () => {
		logger.info("Rep succeeded");
		learning.recordItemResultInAnyPackage(learningPackage.date, currentPitem.kitemId, true);
		// learning.addRep(currentPitem.kitemId, true);
		// updatePitems(draft => {
		// 	draft.shift();
		// });
	}
	const handleFail = () => {
		logger.info("Rep failed");
		learning.recordItemResultInAnyPackage(learningPackage.date, currentPitem.kitemId, false);
		// learning.addRep(currentPitem.kitemId, false);
		// updatePitems(draft => {
		// 	const firstItem = draft.shift();
		// 	if (firstItem)
		// 		draft.splice(2, 0, firstItem);
		// });
	}

	const handleNext = () => {
		logger.debug("Next Kitem...");
		// Make sure that remainingKitemIds is current. Otherwise, if handleNext is for example called through a timer, it might refer to
		// an outdated version from an outdated render cycle.
		const remainingKitemIds = remainingKitemIdsRef.current;
		// If this was just an intro (directly showing the correct answer, without input), move on to the next item if available.
		// No rep is recorded. We'll automatically get back to this item once we handled the next.
		// This way, the user can shortly focus on something else and then we'll see if the intro stuck.
		if (isIntro) {
			// Remember that intro was already shown, so that the item doesn't get stuck in an intro loop
			if (!currentKitemId) throw new Error("Lost track of kitem id");
			introDone.current.push(currentKitemId);
			// Find item in remaining ids and try to set the following id
			// Needs to be so complicated because we might have several intros in a row and therefore might no longer be at index 0.
			const idx = remainingKitemIds.findIndex(kid => kid === currentKitemId);
			const nextId = remainingKitemIds[idx+1];
			if (nextId) {
				logger.debug("Intro done, jumping to idx "+(idx+1)+"/"+nextId);
				setCurrentKitemId(nextId);
			} else {
				logger.debug("Intro done, but no next kitem available. Jumping to idx 0");
				setCurrentKitemId(remainingKitemIds[0]);
			}

		} else {
			const nextKitemId = remainingKitemIds[0];
			logger.debug("Resetting to first item in remainingKitemIds", JSON.stringify(remainingKitemIds, undefined, 4), nextKitemId);
			setCurrentKitemId(nextKitemId);
		}
		setIteration(iteration+1);
	};

	const handleSelfEvalSuccess = () => {
		handleSuccess();
		nextPending.current = true;
		// handleNext();
	}

	const handleSelEvalFail = () => {
		handleFail();
		nextPending.current = true;
		// handleNext();
	}

	const handleSkip = () => {
		logger.info("Skipping kitem "+currentKitemId);
		setSkippedKitemIds([...skippedKitemIds, currentKitemId]);
		setIteration(iteration+1);
		setCurrentKitemId(remainingKitemIds[1]);
	}

	if (nextPending.current) {
		logger.debug("Triggering handleNext...");
		handleNext();
		nextPending.current = false;
	}

	const progress = ((plannedKitemIds.length - remainingKitemIds.length) / plannedKitemIds.length) * 100;
	
	logger.debug("LearningSessionController, iteration "+iteration);


	return <>
		<LinearProgress color="secondary" variant="determinate" value={progress} sx={{position: "absolute", top: 0, width: "100%"}}/>
		<IconButton size="large" onClick={handleSkip} sx={{position: "absolute", right: 0, zIndex: 9999}}><RedoIcon/></IconButton>
		{isSMCKitem(currentKitem) && <>
			{props.selfEvalMode && !isIntro && currentKitem.noSelfEvalMode !== true && <FlashCardController key={iteration} kitem={currentKitem} onSuccess={handleSelfEvalSuccess} onFail={handleSelEvalFail}/>}
			{(!props.selfEvalMode || isIntro || currentKitem.noSelfEvalMode === true) && <SMCController key={iteration} kitem={currentKitem} intro={isIntro} onSuccess={handleSuccess} onFail={handleFail} onNext={handleNext}/>}
		</>}
		{isRocketKitem(currentKitem) &&
			<RocketGameController key={iteration} targetId={currentKitem.targetId} setId={currentKitem.setId} speed={currentPitem.easiness === 0 ? 0.5 : 0.75} maxErrors={2} maxSeconds={120} requiredSuccesses={currentPitem.easiness === 0 ? 5 : 3} onSuccess={handleSuccess} onFail={handleFail} onNext={handleNext} />
		}
		{isImageChooserKitem(currentKitem) &&
			<ImageChooserController key={iteration} kitem={currentKitem} easiness={currentPitem.easiness} onSuccess={props.selfEvalMode ? handleSelfEvalSuccess : handleSuccess} onFail={props.selfEvalMode ? handleSelEvalFail : handleFail} onNext={handleNext} selfEvalMode={props.selfEvalMode}/>
		}
		{isWrittenAnswerKitem(currentKitem) &&
			<WrittenAnswerController
				key={iteration}
				kitem={currentKitem}
				onSuccess={handleSuccess}
				onFail={handleFail}
				onNext={handleNext}
			/>
		}
		<Typography variant="caption" sx={{position: "absolute", right: 0, bottom: 0, margin: 2}}>{currentPitem.state === KitemProgressState.new ? "N" : currentPitem.easiness}</Typography>
	</>;
}
