/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { Box, Button, Dialog, DialogContent, DialogContentText, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import MultiAudioView from "../components/MultiAudioView";
import shuffle from 'lodash.shuffle';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { playSound, AudioUrl, speechSynth, SpeechSynth, isAudioUrl, SoundPlayer } from "../services/sound-player";
import { DisplayImage, DisplayText, Kitem } from "../App";
import logger from "../services/logger";
import { isSpeechSynth } from "../components/SpeechSynth";
import { KitemType } from "../contexts/LearningContext";
import assert from "assert";
const soundJa = require('../assets/jaaa.m4a');

export type SMCKitem = Kitem & {
	type: KitemType.SMC,
	question: SpeechSynth | AudioUrl | DisplayText | DisplayImage,
	answers: ((SpeechSynth | AudioUrl | DisplayText | DisplayImage) & {correct: boolean})[],
	hideAnswerText?: boolean,
	hideQuestionText?: boolean,
	noSelfEvalMode?: boolean,
};

export type SMCKitemUnsaved = Omit<SMCKitem, "id">;

export function isSMCKitem(item: Kitem): item is SMCKitem {
	return (
		(item as SMCKitem).type !== undefined
		&& (item as SMCKitem).type === KitemType.SMC
		&& (item as SMCKitem).question !== undefined
		&& (item as SMCKitem).answers !== undefined
	);
}

export interface SMCControllerProps {
	kitem: SMCKitem,
	intro: boolean,
	correctLast?: boolean,
	onSuccess(): void,
	onFail(): void,
	onNext(): void,
}

export default function SMCController(props: SMCControllerProps) {
	const [currentAnswerIdx, setCurrentAnswerIdx] = useState(0);
	const [showSuccess, setShowSuccess] = useState(false);
	const [showFail, setShowFail] = useState(false);
	const [questionDone, setQuestionDone] =  useState(!isSpeechSynth(props.kitem.question) && !isAudioUrl(props.kitem.question));

	// useEffect(() => {
	// 	logger.debug("Setting SMCController for kitem "+props.kitem.id)
	// 	setQuestionDone(isDisplayText(props.kitem.question));
	// 	setCurrentAnswerIdx(0);
	// 	setShowFail(false);
	// 	setShowSuccess(false);
	// 	if (props.intro) {
	// 		const correctAnswer = props.kitem.answers.find(a => a.correct);
	// 		if (!correctAnswer)
	// 		throw new Error("Failed to find correct item");
	// 		setAllAnswers([correctAnswer]);
	// 	} else {
	// 		const shuffledAnswers = shuffle(props.kitem.answers);
	// 		setAllAnswers(shuffledAnswers);
	// 	}
	// }, [props.kitem, props.resetTrigger]);
	
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

	const item = props.kitem;

	const correctAnswer = props.kitem.answers.find(a => a.correct);
	if (!correctAnswer)
		throw new Error("Failed to find correct item");
	
	const wrongAnswers = props.kitem.answers.filter(a => !a.correct);

	const [allAnswers] = useState(props.intro ? [correctAnswer] : props.correctLast ? [...shuffle(wrongAnswers), correctAnswer]: shuffle(item.answers))

	/**
	 * Play the first available answer as soon as questionDone is true.
	 * TODO: Cancel playing if a sound from a previous call to useEffect is still running. You can use player.stop() to stop a sound.
	 */
	useEffect(() => {
		let player: SoundPlayer | null = null;
		if (questionDone) {
			logger.debug("Initial play of answer #"+currentAnswerIdx);
			const a = allAnswers[currentAnswerIdx];
			if (isAudioUrl(a) || isSpeechSynth(a)) 
				player = playSound(a);
		}
		return () => {
			if (player)
				player.stop();
		}
	}, [currentAnswerIdx, allAnswers, questionDone]);

	useEffect(() => {
		if (!questionDone) {
			if (isSpeechSynth(props.kitem.question)) {
				speechSynth(props.kitem.question.synthText, false, () => setQuestionDone(true));
			} else if (isAudioUrl(props.kitem.question)) {
				const audio = new Audio(props.kitem.question.audioUrl);
				audio.addEventListener('ended', () => setQuestionDone(true));
				audio.play();
			} else {
				throw new Error("Waiting for question to finish playing, but no supported question type available");
			}
		} else
		if (!questionDone && isSpeechSynth(props.kitem.question))
			speechSynth(props.kitem.question.synthText, false, () => setQuestionDone(true));
		// utter(allAnswers[currentAnswerIdx].text);
	}, [questionDone, props.kitem]);

	const playAnswer = () => {
		// utter(allAnswers[currentAnswerIdx].text);
		const a = allAnswers[currentAnswerIdx];
		if (isAudioUrl(a) || isSpeechSynth(a)) 
			playSound(a);
	}

	const playSuccess = () => {
		return new Promise<void>((resolve, reject) => {
			const player = new Audio(soundJa);
			player.addEventListener('ended', () => resolve());
			player.play();
		});
	}

	const playFailTrue = () => {
		return new Promise<void>((resolve, reject) => {
			playSound({ audioUrl: require('../assets/hoppala-das-stimmt-leider-nicht.m4a') }, false, () => {
				const correctAnswer = allAnswers.find(i => i.correct);
				if (correctAnswer) {
					if (isAudioUrl(correctAnswer) || isSpeechSynth(correctAnswer)) {
						playSound(correctAnswer, true, () => {
							console.log("Korrekte Antwort wurde abgespielt");
							resolve();
						});
					} else {
						resolve();
					}
				} else {
					reject(new Error("Could not find correct answer"));
				}
			});
		});
	  };

	const playFailFalse = () => {
		return new Promise<void>((resolve, reject) => {
			playSound({ audioUrl: require('../assets/ups-das-waere-die-richtige-antwort-gewesen.m4a') }, false, () => {
				const correctAnswer = allAnswers.find(i => i.correct);
				if (correctAnswer) {
					if (isAudioUrl(correctAnswer) || isSpeechSynth(correctAnswer)) {
						playSound(correctAnswer, true, () => resolve());
					} else {
						resolve();
					}
				} else
					reject(new Error("Could not find correct answer"));
			});
		});
	}

	const handleClickTrue = () => {
		if (allAnswers[currentAnswerIdx].correct) {
			setShowSuccess(true);
			// playSuccess().then(() => props.onSuccess());
			playSuccess();
			props.onSuccess();
		} else {
			setShowFail(true);
			// playFailTrue().then(() => props.onFail());
			playFailTrue();
			props.onFail();
		}
	}

	const handleClickFalse = () => {
		logger.debug("Click false");
		if (!allAnswers[currentAnswerIdx].correct) {
			setCurrentAnswerIdx(currentAnswerIdx + 1);
		} else {
			setShowFail(true);
			playFailFalse().then(() => {
				logger.debug("Triggering onfail...");
				props.onFail()
			});
		}
	}

	const handleClickPlay = () => {
		if (!showFail) {
			playAnswer();
		} else {
			const correctAnswer = allAnswers.find(i => i.correct); 
			assert(correctAnswer);
			if (isAudioUrl(correctAnswer) || isSpeechSynth(correctAnswer))
				playSound(correctAnswer);
		}
	}

	const handleClickPlayQuestion = () => {
		if (isSpeechSynth(props.kitem.question))
			speechSynth(props.kitem.question.synthText, false, () => setQuestionDone(true));
		else if (isAudioUrl(props.kitem.question))
			playSound(props.kitem.question, false, () => setQuestionDone(true));
		else
			throw Error("Tried to play question, but question does not seem playable");
	}

	return <>
		<Dialog open={false} fullScreen={fullScreen}>
			{/* <DialogTitle>Oops</DialogTitle> */}
			<DialogContent>
			<DialogContentText>
				<Box textAlign="center">
				<Typography variant="h2" gutterBottom>🤷</Typography>
				<IconButton aria-label="delete" size="large">
					<VolumeUpIcon sx={{ fontSize: "60px", color: "black" }} />
				</IconButton><br/><br/>
				{/* <Button variant="contained">⟫ ⇨ →</Button> */}
				<Button variant="contained" size="large" sx={{fontSize: 20}}>⟫&nbsp;&nbsp;⟫&nbsp;&nbsp;⟫</Button>
				</Box>
			</DialogContentText>
			</DialogContent>
		</Dialog>
		<MultiAudioView
			question={item.question}
			onClickTrue={handleClickTrue}
			onClickFalse={handleClickFalse}
			onClickPlay={handleClickPlay}
			onClickPlayQuestion={handleClickPlayQuestion}
			itemCount={allAnswers.length}
			activeItem={currentAnswerIdx}
			answers={allAnswers}
			intro={props.intro}
			fail={showFail}
			success={showSuccess}
			onClickNext={props.onNext}
			questionDone={questionDone}
			onQuestionDone={() => setQuestionDone(true)}
			hideAnswerText={item.hideAnswerText === true}
			hideQuestionText={item.hideQuestionText === true}
		/>
	</>;
}