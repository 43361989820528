import React from 'react';

export interface PromptOptions {
	type?: "text" | "number",
}


export interface AlertProps {
	message: string | React.ReactNode,
	title?: string,
	fullScreen?: boolean,
}

export interface Dialogs {
	prompt(question: string, options?: PromptOptions): Promise<string|null>,
	alert(props: AlertProps): void,
}


const DialogsContext = React.createContext<Dialogs>({
	prompt: async (message) => null,
	alert: (message) => {},
});

export default DialogsContext;
